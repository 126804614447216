<template>
  <v-app>
    <tt-app-bar
      v-if="$auth.isAuthenticated"
      color="white"
      :portal-url="portalUrl"
      :buttons="moduleButtons"
      :menu-items="menuItems"
    >
      <template v-slot:application-buttons>
        <TaskStatus :close-delay="10000" :update-interval="20000" />
      </template>
    </tt-app-bar>

    <v-main>
      <Message />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import EnvProvider from "@tt/vue-environment/EnvProvider";

export default {
  name: "App",
  data: () => ({
    portalUrl: EnvProvider.value("PORTAL_URL"),
    moduleButtons: [],
    statisticsInterval: null
  }),
  computed: {
    menuItems() {
      return this.$router.options.routes
        .filter(item => {
          return !(item?.meta?.label === undefined);
        })
        .filter(item => {
          return item?.meta?.active === true;
        });
    }
  },
  mounted() {
    clearInterval(this.statisticsInterval)
    this.statisticsInterval = setInterval(() => {
      this.$store.dispatch('storeReservationItems/statistics')
    }, 20000)
  }
};
</script>
