import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import InventoryService from "@/services/InventoryService";
import skuFilter from "@/store/modules/synchronisationStock/filters/skuFilter";
import statusFilter from "@/store/modules/synchronisationStock/filters/statusFilter";
import virtualFilter from "@/store/modules/synchronisationStock/filters/virtualFilter";
import targetFilter from "@/store/modules/synchronisationStock/filters/targetFilter";

const synchronisationStockOverview = {
  requestController: null,
  namespaced: true,
  modules: {
    skuFilter,
    statusFilter,
    virtualFilter,
    targetFilter
  },
  state: {
    ...tableState,
    entry: null,
  },
  mutations: {
    ...tableMutations,
    SET_ENTRY(state, entry) {
      state.entry = entry;
    }
  },
  actions: {
    ...tableActions,
    setEntry({ commit }, sku) {
      commit("SET_ENTRY", sku);
    },
    fetchItems({commit, state}, refresh = false) {
      if (refresh || state.items.length === 0) {
        commit("SET_LOADING", true);

        const params = {
          pagination: state.pagination,
          sorting: state.sorting,
          search: state.search
        };

        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();

        InventoryService.virtualStockSyncs.list(params, { signal: this.requestController.signal })
          .then(json => {
            if (json) {
              commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    }
  },
  getters: {
    ...tableGetters
  }
};

export default synchronisationStockOverview;
